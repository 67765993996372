import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from './api-configuration';
import { HttpClient } from '@angular/common/http';
import { ProceduresCovenantModel } from '../core/models/config/covenant/covenantCreate.model';

interface ConvenioApiModel {
  convenioId: number;
  especialidadeId: number;
  porcentagemComissao: string;
  preco: string;
  procedimentoIsAceitaFace: boolean;
  procedimentoNome: string;
  procedimentoStatus: boolean;
  valorCustoProtetico: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConveniosApiService {

  constructor(
    @Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
    private readonly _http: HttpClient,
  ) {
  }

  saveConvenioProcedimento(proc: ProceduresCovenantModel): Promise<void> {
    const data: ConvenioApiModel = {
      convenioId: proc.id,
      especialidadeId: proc.especialidadeId,
      porcentagemComissao: proc.procedimentoPorcentagemComissao
        ? String(proc.procedimentoPorcentagemComissao)
        : undefined,
      procedimentoIsAceitaFace: proc.procedimentoIsAceitaFace,
      preco: proc.preco ? String(proc.preco) : undefined,
      procedimentoNome: proc.procedimentoNome,
      procedimentoStatus: proc.procedimentoStatus,
      valorCustoProtetico: proc.procedimentoValorCustoProtetico
        ? String(proc.procedimentoValorCustoProtetico)
        : undefined,
    };

    return this._http.post<void>(`${this._config.apiBasePath}/procedimento/save`, data).toPromise();
  }

  apagarProcedimentoDeConvenio(id: number): Promise<void> {
    return this._http.delete<void>(
      `${this._config.apiBasePath}/convenio_especialidade_procedimento/${id}`,
    ).toPromise();
  }

  apagarConvenio(idConvenio: number): Promise<void> {
    return this._http
      .delete<void>(`${this._config.apiBasePath}/convenio/${idConvenio}`)
      .toPromise();
  }
}
