import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiConfiguration, API_CONFIGURATION } from "./api-configuration";
import { RespostaPaginada } from "./model/resposta-paginada";

export interface ComissionProcedure {
	id: number | null;
	convenioEspecialidadeProcedimentoId: number;
	valorComissao: number;
	profissionalId: number;
	convenioId: number;
}

export interface SavedComissionProcedure extends ComissionProcedure {
	id: number;
	convenioEspecialidadeProcedimentoProcedimentoNome: string;
	convenioEspecialidadeProcedimentoConvenioEspecialidadeEspecialidadeNome: string;
	profissionalNome: string;
	convenioNome: string;
}

export interface BasicoDentista {
	id: number;
	nome: string;
}

@Injectable({
	providedIn: "root",
})
export class ProfessionalsApiService {
	constructor(
		private readonly _http: HttpClient,
		@Inject(API_CONFIGURATION) private readonly _config: Readonly<ApiConfiguration>
	) {}

	listProfessionalCommission(
		profissionalId: number
	): Promise<RespostaPaginada<SavedComissionProcedure>> {
		return this._http
			.post<RespostaPaginada<SavedComissionProcedure>>(
				`${this._config.apiBasePath}/profissional/getProcedimentosComissaoFixaByProfissional`,
				{
					profissionalId,
				}
			)
			.toPromise();
	}

	saveNewProfessionalComission(proc: ComissionProcedure): Promise<ComissionProcedure> {
		return this._http
			.post<ComissionProcedure>(
				`${this._config.apiBasePath}/profissional/salvarProcedimentoValorFixoComissao`,
				proc
			)
			.toPromise();
	}

	deleteProcedureComission(id: number) {
		return this._http
			.put<void>(`${this._config.apiBasePath}/profissional/removerProcedimentoValorFixoComissao`, {
				id,
			})
			.toPromise();
	}

	listaDentistasAtivos() {
		return this._http
			.get<BasicoDentista[]>(`${this._config.apiBasePath}/profissional/all/dentista/ativo`)
			.toPromise();
	}
}
