import { FormsModel } from '../models/forms/forms.model';
import { Validators } from '@angular/forms';

export const FormProsthetic: FormsModel[] = [
  {
    field: 'nome',
    validators: [Validators.required]
  },
];

export const FormServiceProsthetic: FormsModel[] = [
  {
    field: 'dataPrevistaEntrega',
    validators: [Validators.required]
  },
  {
    field: 'dentes',
    validators: [Validators.required],
  },
  {
    field: 'itemServicoProteticoId',
    validators: [Validators.required]
  },
  {
    field: 'pacienteId',
    validators: [Validators.required]
  },
  {
    field: 'profissionalId',
    validators: [Validators.required]
  },
  {
    field: 'proteticoId',
    validators: [Validators.required]
  },
  {
    field: 'valor',
    validators: [Validators.required]
  }
];

export const ConfirmServiceProsthetic: FormsModel[] = [
  {
    field: 'dataEntrega',
    validators: [Validators.required]
  },
  {
    field: 'valor',
    validators: [Validators.required]
  }
];


