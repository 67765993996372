import { Injectable } from '@angular/core';
import { NgxChartView } from "../../../../pages/reports/indications-report/indications-report.component";

@Injectable({
  providedIn: 'root'
})
export class DashboardQueryService {

  _currentMonth: NgxChartView;

  constructor() { }
}
